import React, { FC } from 'react';
import classNames from 'classnames';
import { Grid, makeStyles, Link } from '@earnup/olympic-lib';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { Theme } from '@material-ui/core/styles';
import { ReactComponent as EarnUpLogo } from '../assets/earnup-logo.svg';
import { SUPPORT_SITE } from '../config';

const styles = (theme: Theme) => ({
  container: {
    height: theme.spacing(9.375),
    background: theme.palette.common.white,
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.14)',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7.75),
      paddingLeft: theme.spacing(13),
      paddingRight: theme.spacing(13),
    },
  },
  hide: {
    display: 'none',
  },
  contact: {
    float: 'right',
    textAlign: 'right',
    fontSize: '12.52px',
    color: 'rgba(0, 0, 0, 0.54)',
    letterSpacing: '0.4px',
    lineHeight: '18px',
  },
  logo: {
    maxHeight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      objectFit: 'contain',
      maxWidth: '100%',
    },
  },
  link: {
    color: theme.palette.primary.main,
    display: 'block',
    textDecoration: 'none',
  },
});
const useStyles = makeStyles(styles);

export const Header: FC<any> = ({ logo }) => {
  const classes = useStyles();
  const [showHeader] = useQueryParam('show_header', BooleanParam);

  const Logo = logo ? (
    <img src={logo} alt="Logo" className={classes.logo} />
  ) : (
    <EarnUpLogo className={classes.logo} />
  );

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      className={classNames(classes.container, {
        [classes.hide]: showHeader === false,
      })}
    >
      <Grid item xs={6}>
        {Logo}
      </Grid>
      <Grid item xs={6}>
        <div className={classes.contact}>
          Questions? Submit a support request at
          <Link
            dataQa="support-link"
            className={classes.link}
            href={`https://${SUPPORT_SITE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {SUPPORT_SITE}
          </Link>
        </div>
      </Grid>
    </Grid>
  );
};
