import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import { PUB_COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from '../config';

const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: PUB_COGNITO_CLIENT_ID,
});

/**
  @deprecated we don't want to be using the frontend cognito client at all anymore. best practive from now on will to be
  to call it from server side.
 */
export async function changePassword(
  username: string,
  oldPassword: string,
  newPassword: string
) {
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: oldPassword,
  });
  cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
  await new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
    })
  );
  return new Promise((resolve, reject) => {
    cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
      if (err) {
        reject(err);
      }
      resolve(result);
    });
  });
}

/**
 @deprecated we don't want to be using the frontend cognito client at all anymore. best practive from now on will to be
 to call it from server side.
 */
export async function forgotPassword(username: string) {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  return new Promise((resolve, reject) =>
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    })
  );
}

/**
 @deprecated we don't want to be using the frontend cognito client at all anymore. best practive from now on will to be
 to call it from server side.
 */
export async function confirmPassword(
  username: string,
  newPassword: string,
  code: string
) {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
  return new Promise((resolve, reject) =>
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: resolve,
      onFailure: reject,
    })
  );
}
