import React from 'react';
import axios from 'axios';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import {
  Typography,
  Spacing,
  Link,
  Gate,
  Grid,
  makeStyles,
} from '@earnup/olympic-lib';

import Page from '../templates/Page';
import { LoginFormWithHeader } from '../components';
import { useIsMobileXs } from '../hooks/isMobile';
import { useProduct } from '../hooks/useProduct';
import { useRedirect } from '../hooks/useRedirect';
import { loginText } from '../constants/i18n';
import { getSubdomain, resolvePartner } from '../utils/theme';
import { Window } from '../globals';
import { Theme } from '@material-ui/core/styles';
import { NO_ENV_VAL_STR, PRIVACY_POLICY, TOC } from '../config';

const styles = (theme: Theme) => ({
  container: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(71),
    },
  },

  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  policy: {
    margin: theme.spacing(4, 3, 2),
  },
});
const useStyles = makeStyles(styles);

export const Login = () => {
  const [{ titleCardCopy, signUpUrl, redirectUrl, isRedirect }] = useProduct();
  const classes = useStyles();
  const [redirect] = useRedirect();
  const isMobile = useIsMobileXs();
  const [textType]: any = useQueryParam(
    'textType',
    withDefault(StringParam, 'default')
  );
  const subdomain = getSubdomain(Window.location.hostname);
  const isPartner = resolvePartner(subdomain);
  const subHeader: any = {
    account_claimed: loginText.alreadyHaveAccountText(isPartner),
    default: titleCardCopy,
  };
  const header: any = {
    account_claimed: loginText.welcomeBack,
    default: loginText.loginToYourAccount,
  };

  const onSubmit = async (event: any) => {
    let response;
    try {
      response = await axios({
        method: 'post',
        url: '/do',
        data: { username: event.email, password: event.password },
      });
    } catch (e) {
      throw Error(loginText.loginFailed);
    }
    redirect(response.data.token);
    return {};
  };

  return (
    <Page>
      <Grid container direction="column" spacing={isMobile ? 2 : 3}>
        <Grid item>
          <LoginFormWithHeader
            onSubmit={onSubmit}
            heading={header[textType]}
            subHeading={subHeader[textType]}
            cta="Login"
            screen="login"
            contrastTop={true}
            fields={[
              {
                type: 'email',
                label: loginText.emailLabel,
                name: 'email',
              },
              {
                type: 'password',
                label: loginText.passwordLabel,
                name: 'password',
              },
            ]}
          >
            <Spacing marginTop={1.5}>
              <Link color="primary" variant="text" href="forgot">
                {loginText.forgotPassword}
              </Link>
            </Spacing>
            <Gate condition={isRedirect}>
              <Spacing marginTop={1.5}>
                <Typography>
                  {loginText.dontHaveAccountYet}{' '}
                  <Link
                    ContainerProps={{ display: 'inline' }}
                    color="primary"
                    variant="text"
                    href={signUpUrl}
                  >
                    {loginText.createOneNow}
                  </Link>
                </Typography>
              </Spacing>
            </Gate>
          </LoginFormWithHeader>
          {TOC !== NO_ENV_VAL_STR && (
            <Grid item className={classes.container}>
              <Typography
                dataQa="service-terms"
                variant="body2"
                color="textSecondary"
                className={classes.policy}
              >
                By logging in, you are agreeing to our{' '}
                <Link
                  dataQa="terms"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={TOC}
                >
                  Terms of Service{' '}
                </Link>
                and your personal information would be subject to our{' '}
                <Link
                  dataQa="privacy-policy"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={PRIVACY_POLICY}
                >
                  privacy policy.
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};
