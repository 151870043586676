import { gql } from '@apollo/client';

export const GET_PARTNER_CONFIG = gql`
  query GetPartnerConfiguration($subdomain: String, $partnerId: String) {
    get_partner_configuration(subdomain: $subdomain, partner_id: $partnerId) {
      theme {
        palette {
          primary {
            main
            light
            dark
            contrastText
          }
          secondary {
            main
            light
            dark
            contrastText
          }
        }
      }
      logo
    }
  }
`;
