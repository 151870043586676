import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import {
  makeStyles,
  Typography,
  CardContent,
  Paper,
  Gate,
} from '@earnup/olympic-lib';
import { LoginForm } from './LoginForm';
import { FormHeader } from './FormHeader';
import { loginText } from 'src/constants/i18n';

const styles = (theme: Theme) => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    background: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(71),
    },
  },
  welcome: {
    margin: theme.spacing(0, 0, 2),
  },
  children: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});
const useStyles = makeStyles(styles);

export const LoginFormWithHeader: FC<{
  contrastTop?: boolean;
  onSubmit: (event: any) => object;
  initialValues?: object;
  validationSchema?: object;
  heading: string;
  subHeading?: string;
  displayHeader?: boolean;
  cta: string;
  fields: any[];
  children?: any;
  buttonProp?: any;
  successMessage?: string;
  screen: string;
}> = ({
  contrastTop,
  onSubmit,
  validationSchema,
  initialValues,
  heading,
  subHeading,
  displayHeader = true,
  cta,
  fields,
  children,
  buttonProp,
  successMessage,
  screen,
}) => {
  const classes = useStyles();
  const showWelcome = !subHeading;
  return (
    <Paper elevation={0} className={classes.paper}>
      <CardContent>
        <Gate condition={displayHeader}>
          <FormHeader
            contrastTop={contrastTop}
            heading={heading}
            subHeading={subHeading}
          />
        </Gate>
        <Gate condition={showWelcome}>
          <Typography className={classes.welcome}>
            {loginText.welcome}
          </Typography>
        </Gate>
        <LoginForm
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
          cta={cta}
          fields={fields}
          buttonProp={buttonProp}
          successMessage={successMessage}
          screen={screen}
        />
        <Box className={classes.children}>{children}</Box>
      </CardContent>
    </Paper>
  );
};
