import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { EARNUP_JS_COOKIE, EARNUP_ENV } from '../config';

function isDev(env: string) {
  return env === 'development';
}

export const Home = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies] = useCookies([EARNUP_JS_COOKIE]);

  const [decodedToken, setDecodedToken] = useState<
    object | string | undefined | null
  >();
  useEffect(() => {
    (async () => {
      if (!cookies[EARNUP_JS_COOKIE]) {
        window.location.assign('login');
      } else {
        setDecodedToken(cookies[EARNUP_JS_COOKIE]);
      }
    })();
  }, [cookies]);

  return isDev(EARNUP_ENV) && decodedToken ? (
    <div>
      <h2>Token status</h2>
      <h2>Logged in as</h2>
      <pre>{JSON.stringify(cookies[EARNUP_JS_COOKIE], null, 2)};</pre>
    </div>
  ) : (
    <div></div>
  );
};
