export const PINE_TREE_LENDER = 'pinetreelender';
export const LOAN_MONKEY = 'loanmonkey';
export const ENTERPRISE = 'enterprise';

/**
 * There's currently no service/schema we can query to get a list of all our
 * possible partners we can map the hostname to. This is a temporary solution
 * for now.
 */
export const PARTNER_LIST = [PINE_TREE_LENDER, LOAN_MONKEY, ENTERPRISE];
