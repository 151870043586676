import React, { FC } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Theme } from '@material-ui/core/styles';

import {
  FormGroup,
  TextField,
  Email,
  Password,
  Grid,
  SubmitButton as Button,
  Form,
  Alert,
  Gate,
  Spacing,
  makeStyles,
} from '@earnup/olympic-lib';
import { getFormMessage, isSuccess } from '../utils/form';

const styles = (theme: Theme) => ({
  formContainer: {
    width: '100%',
  },
  button: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: -theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(39),
      height: theme.spacing(6),
      marginTop: -theme.spacing(2),
    },
  },
  message: {
    marginBottom: theme.spacing(4),
  },
});
const useStyles = makeStyles(styles);

const match = (type: string) => {
  if (type === 'password') {
    return Password;
  } else if (type === 'email') {
    return Email;
  } else {
    return TextField;
  }
};

export const LoginForm: FC<{
  onSubmit: (event: any) => object;
  initialValues?: object;
  validationSchema?: object;
  cta: string;
  fields: any[];
  children?: any;
  buttonProp?: any;
  successMessage?: string;
  screen: string;
}> = ({
  onSubmit,
  validationSchema,
  initialValues,
  cta,
  fields,
  buttonProp,
  successMessage,
  screen,
}) => {
  const classes = useStyles();
  const [parentOrigin]: any = useQueryParam('loginUrl', StringParam);

  return (
    <div className={classes.formContainer}>
      <Form
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {(formProps: any) => {
          const { status, isSubmitting } = formProps;
          const message = getFormMessage(status);
          const success = isSuccess(status);
          return (
            <>
              <Gate
                condition={success && successMessage}
                alternate={
                  <Gate condition={Boolean(message)}>
                    <div className={classes.message}>
                      <Alert variant="filled" severity="error">
                        {message}
                      </Alert>
                    </div>
                  </Gate>
                }
              >
                <div className={classes.message}>
                  <Alert variant="filled" severity="success">
                    {successMessage}
                  </Alert>
                </div>
              </Gate>
              <FormGroup>
                {fields.map((field) => {
                  const LoginFormField = match(field.type);
                  return (
                    <LoginFormField
                      dataQa={`${field.name}-field`}
                      key={`${field.name}-field`}
                      label={field.label}
                      id={field.name}
                      name={field.name}
                      disabled={field.disabled}
                    />
                  );
                })}
              </FormGroup>
              <Grid
                container
                direction="column"
                justify="flex-end"
                align="center"
              >
                <Gate
                  condition={Boolean(buttonProp)}
                  alternate={
                    <Spacing marginTop={3}>
                      <Button
                        dataQa={`login-form-submit-button-${screen}`}
                        type="submit"
                        className={classes.button}
                        disabled={isSubmitting}
                        fullWidth
                      >
                        {cta}
                      </Button>
                    </Spacing>
                  }
                >
                  <Spacing marginTop={4}>
                    <Button
                      dataQa={`login-form-submit-button-${screen}`}
                      type="submit"
                      className={classes.button}
                      disabled={isSubmitting}
                      fullWidth
                    >
                      {cta}
                    </Button>
                  </Spacing>
                  <Spacing marginTop={4}>{buttonProp}</Spacing>
                </Gate>
              </Grid>
            </>
          );
        }}
      </Form>
    </div>
  );
};
