import React from 'react';
import { Typography, Spacing, Link } from '@earnup/olympic-lib';

import Page from '../templates/Page';
import { LoginFormWithHeader } from '../components';
import { useRedirect } from '../hooks/useRedirect';
import createValidationSchema from '../validation/create';
import axios from 'axios';

export const Create = () => {
  const [redirect] = useRedirect();

  const onSubmit = async (event: any) => {
    let response;
    try {
      response = await axios({
        method: 'post',
        url: '/do',
        data: { username: event.email, password: event.password, create: true },
      });
    } catch (e) {
      console.log(e);
      throw Error('Account creation failed');
    }
    redirect(response.data.token);
    return {};
  };

  const validationSchema = createValidationSchema();

  return (
    <Page>
      <LoginFormWithHeader
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        heading="Create your account"
        cta="Create Account"
        screen="create"
        contrastTop={true}
        fields={[
          {
            type: 'email',
            label: 'Email',
            name: 'email',
          },
          {
            type: 'password',
            label: 'Password',
            name: 'password',
          },
        ]}
      >
        <Spacing marginTop={1.5}>
          <Typography>
            Already have an account?{' '}
            <Link
              ContainerProps={{ display: 'inline' }}
              color="primary"
              variant="text"
              href="login"
            >
              Log in
            </Link>
          </Typography>
        </Spacing>
      </LoginFormWithHeader>
    </Page>
  );
};
