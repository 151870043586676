import React, { useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import axios from 'axios';

export const SemiAuthEmbed = () => {
  const [parentOrigin]: any = useQueryParam('origin', StringParam);
  useEffect(() => {
    const handler = async (event: any) => {
      let response: any;
      if (event.data.type === 'getAuth') {
        try {
          response = await axios({
            method: 'post',
            url: '/identify',
            data: {},
          });
          window.parent.postMessage(
            {
              type: 'personData',
              jwtTokenPayload: response.data.jwtTokenPayload,
            },
            parentOrigin
          );
        } catch (e) {
          console.log(e);
          throw Error('Semi Auth /identify endpoint failed');
        }
      }
    };
    window.addEventListener('message', handler);
    window.parent.postMessage({ type: 'semiAuthEmbedRendered' }, parentOrigin);

    return () => window.removeEventListener('message', handler);
  }, []);

  return <div></div>;
};
