import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { usePerson } from 'src/hooks/usePerson';
import axios from 'axios';

export const SemiAuthRequestTokenEmbed = () => {
  const [requestToken] = useQueryParam('rt', StringParam);
  const [parentOrigin]: any = useQueryParam('origin', StringParam);
  const { loading, email } = usePerson(requestToken);
  // Because we now have a hook, but this screen still uses legacy message passing, we need custom state
  // to trigger things neatly
  const [getAuthTriggerFired, setGetAuthTriggerFired] = useState(false);
  useEffect(() => {
    const handler = async (event: any) => {
      if (event.data.type === 'getAuth' && !getAuthTriggerFired) {
        setGetAuthTriggerFired(true);
      }
    };
    window.parent.postMessage(
      { type: 'semiAuthRequestTokenRendered' },
      parentOrigin
    );
    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  // Separate useEffect to monitor both the hook and externally triggered state
  useEffect(() => {
    if (!loading && getAuthTriggerFired) {
      const stringifiedObj = JSON.parse(
        JSON.stringify({
          type: 'semiAuthRequestTokenComplete',
          tokenInfo: { email },
        })
      );
      window.parent.postMessage(stringifiedObj, parentOrigin);
    }
  }, [loading, getAuthTriggerFired, email, parentOrigin]);

  return <div></div>;
};
