import React, { useState } from 'react';
import { Theme } from '@material-ui/core/styles';
import {
  Spacing,
  Link,
  Gate,
  Paper,
  CardContent,
  CardActions,
  Typography,
  Button,
  makeStyles,
} from '@earnup/olympic-lib';
import { LoginFormWithHeader } from '../components';
import { confirmPassword, forgotPassword } from '../services/cognito';
import { PW_RESET_METHOD } from '../config';
import { withRouter } from 'react-router-dom';
import createValidationSchema from '../validation/change';
import Page from '../templates/Page';

const validationSchema = createValidationSchema();

const styles = (theme: Theme) => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    background: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(71),
    },
  },
  header: {
    fontSize: theme.typography.fontSize * 1.45,
  },
  button: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: -theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(39),
      height: theme.spacing(6),
      marginTop: -theme.spacing(2),
    },
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
  },
});
const useStyles = makeStyles(styles);

const _Forgot = (props: any) => {
  const classes = useStyles();
  const [username, setUsername] = useState<string>();

  const onSubmit = async (event: any) => {
    if (event.code) {
      await confirmPassword(username!, event.newPassword, event.code);
      props.history.push('login');
    } else {
      await forgotPassword(event.email);
      setUsername(event.email);
    }
  };

  return (
    <Page>
      <Gate
        condition={Boolean(username)}
        alternate={
          <LoginFormWithHeader
            onSubmit={onSubmit}
            heading="Forgot password"
            subHeading="Please enter your email address and we'll send you a link to reset your password."
            cta="Submit"
            screen="forgot"
            fields={[
              {
                type: 'email',
                label: 'Enter your email',
                name: 'email',
              },
            ]}
          >
            <Spacing marginTop={1.5}>
              <Link color="primary" variant="text" href="login">
                Back to log in
              </Link>
            </Spacing>
          </LoginFormWithHeader>
        }
      >
        <Gate
          condition={PW_RESET_METHOD === 'link'}
          alternate={
            <LoginFormWithHeader
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              heading="Forgot password"
              subHeading="Please enter the code sent to your email."
              cta="Submit"
              screen="forgot"
              fields={[
                {
                  type: 'text',
                  label: 'Enter your code',
                  name: 'code',
                },
                {
                  type: 'password',
                  label: 'Enter your new password',
                  name: 'newPassword',
                },
                {
                  type: 'password',
                  label: 'Confirm your new password',
                  name: 'confirmPassword',
                },
              ]}
            >
              <Spacing marginTop={1.5}>
                <Link color="primary" variant="text" href="login">
                  Back to log in
                </Link>
              </Spacing>
            </LoginFormWithHeader>
          }
        >
          <Paper elevation={0} className={classes.paper}>
            <CardContent>
              <Spacing marginBottom={3}>
                <Typography variant="h5" className={classes.header}>
                  A password reset link was sent to your email
                </Typography>
              </Spacing>
              <Spacing marginBottom={6}>
                <Typography variant="body1">
                  Please check your email to reset your password.
                </Typography>
              </Spacing>
              <CardActions>
                <Button
                  dataQa="forget-screen-back-to-login"
                  className={classes.button}
                >
                  <Link href="login" className={classes.link}>
                    Back To Log In
                  </Link>
                </Button>
              </CardActions>
            </CardContent>
          </Paper>
        </Gate>
      </Gate>
    </Page>
  );
};

export const Forgot = withRouter(_Forgot);
