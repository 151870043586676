import { useQueryParam, StringParam } from 'use-query-params';
import { ENTERPRISE } from '../constants/theme';
import { getSubdomain } from '../utils/theme';
import { Window } from '../globals';

export const useProduct = () => {
  const [redirectUrl] = useQueryParam('redirect_url', StringParam);

  let supportEmailAddress = 'support@earnup.com';
  let titleCardCopy;
  let signUpUrl = 'create';
  const loginUrl = 'login';
  const subdomain = getSubdomain(Window.location.hostname);
  const isEnterprise =
    (redirectUrl && redirectUrl.includes('enterprise')) ||
    subdomain === ENTERPRISE;

  if (redirectUrl && redirectUrl.includes('matchup')) {
    supportEmailAddress = 'matchup@earnup.com';
    signUpUrl = 'https://matchup.earnup.com/signup';
  } else if (redirectUrl && redirectUrl.includes('movein')) {
    supportEmailAddress = 'movein@earnup.com';
    signUpUrl = 'https://saveup.earnup.com';
  } else if (redirectUrl && redirectUrl.includes('flexpay')) {
    supportEmailAddress = 'flexpay@earnup.com';
  } else if (isEnterprise) {
    supportEmailAddress = 'partnerwithus@earnup.com';
    signUpUrl = 'https://enterprise.earnup.com/create-account';
  }

  const isRedirect = Boolean(redirectUrl);

  return [
    {
      supportEmailAddress,
      titleCardCopy,
      signUpUrl,
      loginUrl,
      redirectUrl,
      isRedirect,
    },
  ];
};
