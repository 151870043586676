import React, { useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import {
  Email,
  Password,
  Grid,
  SubmitButton,
  Form,
  Typography,
  Link,
  Alert,
  LoadingGate,
  makeStyles,
} from '@earnup/olympic-lib';

import { LoginForm } from '../components';
import { ENTERPRISE_BORROWER_ENROLLMENT_FLOW } from '../constants/i18n';
import { isDevClientSide } from '../utils/envutils';
import { usePerson } from '../hooks/usePerson';

import createValidationSchema from '../validation/partnerBorrowerEnroll';

const isAllowedOrigin = (origin = ''): boolean => {
  const devOverride = origin.match(/localhost:\d+$/) && isDevClientSide();
  return Boolean(origin.match(/earnup.com$/) || devOverride);
};

const styles = (theme: any) => {
  return {
    container: {
      padding: theme.spacing(4),
    },
    heading: {
      paddingTop: theme.spacing(1),
    },
    instructions: {
      fontSize: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(1.5),
    },
    error: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      maxWidth: theme.spacing(46.25),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    // TODO: This will break until the next release of Olympic
    // submitButton: {
    //   backgroundColor: theme.palette.grey[500],
    //   '&:hover': {
    //     backgroundColor: theme.palette.enterprise.primary,
    //   },
    // },
  };
};
const useStyles = makeStyles(styles);

const ErrorState = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      xs={12}
      md={7}
      className={classes.error}
    >
      <Alert variant="filled" severity="error">
        Sorry, looks like we're having trouble validating your token and
        retrieving your account details.
      </Alert>
    </Grid>
  );
};

export const BorrowerEnrollmentAccountCreation = () => {
  const classes = useStyles();
  const [parentOrigin]: any = useQueryParam('origin', StringParam);
  const [requestToken] = useQueryParam('rt', StringParam);
  const origin = isAllowedOrigin(parentOrigin)
    ? parentOrigin
    : 'https://app.earnup.com';
  const sendToParent = (type: string, content: any) => {
    window.parent.postMessage({ type, content }, origin);
  };

  const { loading, createAccount, email } = usePerson(requestToken);
  const [isComplete, setComplete] = useState(false);
  const complete = () => setComplete(true);

  const validationSchema = createValidationSchema();
  const initialValues = {
    password: '',
    email,
  };
  const onSubmit = (values: any) => {
    complete();
    createAccount(values?.password)
      .then((creationResp: any) => {
        sendToParent('success', creationResp);
      })
      .catch((err: any) => {
        sendToParent('error', err);
      });
  };

  const showLoadingSpinner = loading || isComplete;
  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {(formProps: any) => {
        const { status, isSubmitting } = formProps;
        return (
          <>
            <LoadingGate condition={!showLoadingSpinner}>
              <Grid
                container
                item
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                xs={12}
                md={7}
                className={classes.container}
              >
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="left"
                  className={classes.heading}
                >
                  <Typography variant="h6" Component="h1">
                    {ENTERPRISE_BORROWER_ENROLLMENT_FLOW.HEADER}
                  </Typography>
                </Grid>
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="left"
                  className={classes.instructions}
                >
                  <Typography>
                    {/* TODO: Pull in bank name https://earnup.atlassian.net/browse/APPS-1816 */}
                    {ENTERPRISE_BORROWER_ENROLLMENT_FLOW.INSTRUCTIONS()}
                  </Typography>
                </Grid>
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="left"
                  className={classes.instructions}
                >
                  <Email
                    label="Email"
                    value={email ? email : ''}
                    dataQa="readonly-email"
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="left"
                  className={classes.instructions}
                >
                  <Password
                    label="Password"
                    dataQa="sign-up-password"
                    helperText="10+ characters, at least 1 uppercase and a number"
                  />
                </Grid>
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="left"
                  className={classes.instructions}
                >
                  {/* TODO: Update these links with more accurate values  */}
                  <Typography variant="caption">
                    By clicking “Create”, you agree to EarnUp's{' '}
                    <Link
                      href="https://enrollment.earnup.com/assets/docs/e-sign-consent.pdf"
                      className={classes.link}
                      variant="caption"
                      target="_blank"
                    >
                      E-sign consent
                    </Link>
                    ,{' '}
                    <Link
                      href="https://app.earnup.com/account/docs/toc_flexpay_v2022_05_17.pdf"
                      className={classes.link}
                      variant="caption"
                      target="_blank"
                    >
                      Terms
                    </Link>
                    , and{' '}
                    <Link
                      href="https://earnup.com/privacy-policy/"
                      className={classes.link}
                      variant="caption"
                      target="_blank"
                    >
                      Privacy Policy.
                    </Link>
                  </Typography>
                </Grid>
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="right"
                  className={classes.instructions}
                >
                  <SubmitButton
                    dataQa={`sign-up-form-SubmitButton-borrower`}
                    type="submit"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                  >
                    Create
                  </SubmitButton>
                </Grid>
              </Grid>
            </LoadingGate>
          </>
        );
      }}
    </Form>
  );
};
