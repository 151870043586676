import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { useQueryParam, StringParam } from 'use-query-params';
import { useCookies } from 'react-cookie';
import {
  Gate,
  makeStyles,
  Button,
  Link,
  Typography,
} from '@earnup/olympic-lib';

import { LoginFormWithHeader } from '../components';
import { changePassword } from '../services/cognito';
import createValidationSchema from '../validation/change';
import { EARNUP_JS_COOKIE } from '../config';
import { useProduct } from '../hooks/useProduct';
import Page from '../templates/Page';

const styles = (theme: Theme) => ({
  button: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: -theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(39),
      height: theme.spacing(6),
      marginTop: -theme.spacing(2),
    },
  },
});
const useStyles = makeStyles(styles);

export const Change = (props: any) => {
  const classes = useStyles();
  const [resetCode] = useQueryParam('reset_code', StringParam);
  const [{ redirectUrl, isRedirect }] = useProduct();
  const [cookies] = useCookies([EARNUP_JS_COOKIE]);
  const email = cookies[EARNUP_JS_COOKIE] && cookies[EARNUP_JS_COOKIE].email;

  const onSubmit = async (event: any) => {
    await changePassword(email, event.currentPassword, event.newPassword);
  };

  const navBack = (props: any) => <Link {...props} to={redirectUrl} />;
  const backButton = (
    <Button
      variant="outlined"
      className={classes.button}
      Component={React.forwardRef((props: any) => navBack(props))}
      dataQa="change-screen-back-button"
      fullWidth
    >
      <Typography variant="button">Back</Typography>
    </Button>
  );

  const validationSchema = createValidationSchema();

  const initialValues = {
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
  };

  const alternate = (
    <LoginFormWithHeader
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      heading="Reset password"
      subHeading="Please enter your new password."
      cta="reset password"
      screen="change"
      fields={[
        {
          type: 'password',
          label: 'Current password',
          name: 'currentPassword',
        },
        {
          type: 'password',
          label: 'New password',
          name: 'newPassword',
        },
        {
          type: 'password',
          label: 'Confirm new password',
          name: 'confirmPassword',
        },
      ]}
      buttonProp={isRedirect ? backButton : null}
      successMessage="Your password has been successfully updated. Click back
      to return to your account."
    />
  );

  return (
    <Page>
      <Gate condition={resetCode} alternate={alternate}>
        <LoginFormWithHeader
          onSubmit={onSubmit}
          heading="Enter code"
          cta="reset password"
          screen="change"
          fields={[
            {
              type: 'text',
              label: 'Code',
              name: 'code',
            },
          ]}
        />
      </Gate>
    </Page>
  );
};
