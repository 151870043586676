import React, { FC } from 'react';
import { makeStyles, Grid, Gate } from '@earnup/olympic-lib';
import { ReactComponent as PoweredByLogo } from '../assets/powered-by-logo.svg';
import { useQuery } from '@apollo/client';
import { GET_PARTNER_CONFIG } from '../gql/partnerConfig';
import { getSubdomain } from '../utils/theme';

export type PageProps = object;

const pageStyles = (theme: any) => ({
  wrapper: {
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  body: {
    flexGrow: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(22.5),
      alignItems: 'center',
    },
  },
  poweredByLogo: {
    width: theme.spacing(16.25),
  },
});

const usePageStyles = makeStyles(pageStyles);

/**
 * upcoming:
 * new designs will remove the green contrast header and we'll likely
 * see changes in behavior at different breakpoints
 *
 * this Page wrapper will temporarily:
 * 1. handle screens with a green contrast header (/login and /create)
 * 2. allow screens without a green contrast header to have width 100%
 * and be embeddable in other applications
 */
const Page: FC<PageProps> = ({ children }) => {
  const classes = usePageStyles();

  const subdomain = getSubdomain(window.location.hostname);

  const { loading, error, data } = useQuery(GET_PARTNER_CONFIG, {
    variables: { subdomain },
  });

  // If we are getting partner config back it means it's a partner
  const isPartner = Boolean(data?.get_partner_configuration);

  return (
    <Grid
      container
      className={classes.wrapper}
      justify="flex-start"
      alignItems="stretch"
      wrap="nowrap"
    >
      <Grid item className={classes.body}>
        {children}
        <Gate condition={Boolean(isPartner)}>
          <Grid item className={classes.footer}>
            <PoweredByLogo className={classes.poweredByLogo} />
          </Grid>
        </Gate>
      </Grid>
    </Grid>
  );
};

export default Page;
