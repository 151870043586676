import * as Yup from 'yup';

const email = Yup.string()
  .email('Please enter a valid email')
  .required('Email is required');

export const password = Yup.string()
  .required(
    'Password must be 10 characters or longer and contain an uppercase character and a number'
  )
  .matches(/.*[A-Z]/gm, 'Password must contain an uppercase character')
  .matches(/.*[0-9]/gm, 'Password must contain a number')
  .min(10, 'Password must be at least 10 characters');

const createValidationSchema = () =>
  Yup.object().shape({
    email,
    password,
  });

export default createValidationSchema;
