import React from 'react';
import { Link } from '@earnup/olympic-lib';

import {
  USER_NOT_FOUND_EXCEPTION,
  INVALID_PARAMETER_EXCEPTION,
  MISSING_USERNAME,
  USERNAME_ALREADY_EXISTS,
} from '../constants/errors';

export const getFormMessage = (status: any) => {
  if (!status) {
    return null;
  }

  if ('object' === typeof status) {
    console.log('K: ', Object.keys(status));
  }

  let { message, code } = status;

  /**
   * status.message can be either string or stringified object (which is also a sting type)
   * JSON.parse throws an exception when executed on a string, but works fine when
   * executed on stringified object.
   * Since we don't have a way to identify whether it is just a string or stringified object
   * we are trying to parse first.
   * TODO: We need to make sure that all Errors in the app are similarly structured
   * so we don't have to have these workarounds.
   */
  let parsedMessage;
  try {
    parsedMessage = JSON.parse(message);
  } catch (e) {
    parsedMessage = message;
  }

  if ('object' === typeof parsedMessage) {
    code = parsedMessage.code;
    message = parsedMessage.message;
  }

  if (message === MISSING_USERNAME) {
    return 'Please enter a valid email.';
  }

  if (
    code === USER_NOT_FOUND_EXCEPTION ||
    code === INVALID_PARAMETER_EXCEPTION
  ) {
    return 'Reset password failed. Please try again.';
  }

  // Todo: Need to get the correct login url for flow (eg, D2C, partner, etc)
  if (code === USERNAME_ALREADY_EXISTS) {
    return (
      <>
        Account creation failed, an account already exists for this email
        address. Please{' '}
        <Link
          ContainerProps={{ display: 'inline' }}
          variant="text"
          href="login"
          target="_parent"
          style={{
            fontWeight: 'bold',
            color: '#fff',
            textDecoration: 'underline',
          }}
        >
          log in
        </Link>{' '}
        to your account.
      </>
    );
  }
  return message;
};

export const isSuccess = (status: any) => {
  return typeof status === 'string' && status.includes('success');
};
