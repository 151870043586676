import { getConfigVal, EnvType } from '@earnup/spa-container';

export const EARNUP_COOKIE_DOMAIN = getConfigVal('EARNUP_COOKIE_DOMAIN');

// HTTP-only cookie names set by login
export const EARNUP_ID_COOKIE =
  getConfigVal('EARNUP_ID_COOKIE') || 'earnup_id_token';
export const EARNUP_REFRESH_COOKIE =
  getConfigVal('EARNUP_REFRESH_COOKIE') || 'earnup_refresh_token';
export const EARNUP_UID_COOKIE =
  getConfigVal('EARNUP_UID_COOKIE') || 'earnup_uid_token';

// Cognito config
export const COGNITO_USER_POOL_ID = getConfigVal('COGNITO_USER_POOL_ID');
export const COGNITO_AWS_REGION =
  getConfigVal('COGNITO_AWS_REGION') || 'us-west-2';
export const PUB_COGNITO_CLIENT_ID = getConfigVal('PUB_COGNITO_CLIENT_ID');

export const EARNUP_ENV = getConfigVal('EARNUP_ENV');
export const EARNUP_JS_COOKIE =
  getConfigVal('EARNUP_JS_COOKIE') || 'earnup_js_token';
export const ROLLBAR_ACCESS_TOKEN = getConfigVal('ROLLBAR_ACCESS_TOKEN');
export const ROLLBAR_ACCESS_TOKEN_SERVER = getConfigVal(
  'ROLLBAR_ACCESS_TOKEN_SERVER'
);
export const SEGMENT_KEY = getConfigVal('SEGMENT_KEY');
export const UNCLAIMED_COGNITO_USERNAME =
  getConfigVal('UNCLAIMED_COGNITO_USERNAME') ||
  'unclaimed_identity_account@earnup.com';
export const UNENROLLED_COGNITO_USERNAME =
  getConfigVal('UNENROLLED_COGNITO_USERNAME') ||
  'unenrolled_identity_account@earnup.com';
export const UNENROLLED_IDENTITY_COGNITO_USERNAME =
  getConfigVal('UNENROLLED_IDENTITY_COGNITO_USERNAME') ||
  'unenrolled_token_identity_account@earnup.com';

// gql config
export const GRAPHQL_ENDPOINT = getConfigVal('GRAPHQL_ENDPOINT');

// code or link for pw reset
export const PW_RESET_METHOD = getConfigVal(
  'PW_RESET_METHOD',
  EnvType.String,
  'code'
);

export const DEFAULT_REDIRECT_URL = getConfigVal('DEFAULT_REDIRECT_URL', '/');

export const PEOPLE_SERVICE_URL = getConfigVal('PEOPLE_SERVICE_URL');

// PUBLIC_URL is not dynamic, do not use getConfigVal
// https://create-react-app.dev/docs/using-the-public-folder/
export const PUBLIC_URL = process.env.PUBLIC_URL;

export const PEOPLE_SERVICE_API_KEY = process.env.PEOPLE_SERVICE_API_KEY;

export const EARNUP_LOAN_SERVICE_URL = process.env.EARNUP_LOAN_SERVICE_URL;
export const EARNUP_LOAN_SERVICE_API_KEY =
  process.env.EARNUP_LOAN_SERVICE_API_KEY;

// these are to be used on the server side only!
export const COGNITO_CLIENT_ID = process.env.COGNITO_CLIENT_ID;
export const COGNITO_SECRET_KEY = process.env.COGNITO_SECRET_KEY;

// show errors in prod if the force is with you
export const JEDI_HOLOCRON_KEY = process.env.JEDI_HOLOCRON_KEY;

// Workaround for a non-deployable change on dev
export const NO_ENV_VAL_STR = 'NONE';

export const PRIVACY_POLICY = getConfigVal('PRIVACY');

export const TOC = getConfigVal('TOC', EnvType.String, NO_ENV_VAL_STR);
export const SUPPORT_SITE = getConfigVal(
  'SUPPORT_SITE',
  EnvType.String,
  'support.earnup.com'
);

// Release flags
export const RF_USE_PARTNER_LOGO =
  getConfigVal('RF_USE_PARTNER_LOGO', EnvType.Boolean) || false;
