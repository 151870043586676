import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { GRAPHQL_ENDPOINT } from './config';
import { onError } from '@apollo/client/link/error';

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (
    graphQLErrors &&
    graphQLErrors[0].message === 'Context creation failed: invalid auth'
  ) {
    console.log('graphql error', graphQLErrors[0].message);
  }

  forward(operation);
});

(async () => {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    // Compose chain link and terminate with sending GQL operation
    link: from([errorLink, httpLink]),
  });

  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
