import * as Yup from 'yup';

const currentPassword = Yup.string().typeError(
  'Please enter your current password'
);

const newPassword = Yup.string()
  .typeError(
    'Password must be 10 characters or longer and contain an uppercase character and a number'
  )
  .required(
    'Password must be 10 characters or longer and contain an uppercase character and a number'
  )
  .when('currentPassword', (currentPassword: string) => {
    return Yup.string()
      .typeError(
        'Password must be 10 characters or longer and contain an uppercase character and a number'
      )
      .required(
        'Password must be 10 characters or longer and contain an uppercase character and a number'
      )
      .matches(/^\S+$/gm, 'No spaces allowed in password')
      .matches(/.*[A-Z]/gm, 'Password must contain an uppercase character')
      .matches(/.*[0-9]/gm, 'Password must contain a number')
      .min(10, 'Password must be at least 10 characters')
      .test(
        'new password',
        'New password cannot be the same as current password',
        (newPassword: string) => currentPassword !== newPassword
      );
  });
const confirmPassword = Yup.string()
  .typeError('Please confirm your new password.')
  .when('newPassword', (newPassword: string) => {
    return Yup.string()
      .typeError('Please confirm your new password.')
      .test(
        'confirm password',
        'Passwords do not match, please try again',
        (confirmPassword: string) => confirmPassword === newPassword
      );
  });

const createValidationSchema = () =>
  Yup.object().shape({
    currentPassword,
    newPassword,
    confirmPassword,
  });

export default createValidationSchema;
