import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import { makeStyles } from '@earnup/olympic-lib';

import { LoginForm } from '../components';
import createValidationSchema from '../validation/create';
import { createScreenText } from '../constants/i18n';
import { Window } from '../globals';

const styles = (theme: any) => ({
  container: {
    paddingTop: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);

export const CreateEmbed = () => {
  const classes = useStyles();
  const containerRef = React.useRef() as React.MutableRefObject<any>;
  const [submitSuccess, setSubmitSuccess]: any = useState(false);
  const [customerId]: any = useQueryParam(
    'customer_id',
    withDefault(StringParam, '')
  );
  const [uisId]: any = useQueryParam('uis_id', withDefault(StringParam, ''));
  const [email]: any = useQueryParam('email', withDefault(StringParam, ''));
  const [parentOrigin]: any = useQueryParam('origin', StringParam);
  const decodedEmail = atob(email);
  const decodedCustomerId = atob(customerId);
  const decodedUisId = atob(uisId);
  const initialValues = { email: decodedEmail };
  const validationSchema = createValidationSchema();
  const disableEmail = email !== undefined && email !== '' && email !== null;

  const handleSubmit = async (event: any) => {
    const response = await axios({
      method: 'post',
      url: '/create-account',
      data: {
        username: event.email,
        password: event.password,
        customer_id: decodedCustomerId,
        uis_id: decodedUisId,
      },
    }).catch((err) => {
      throw Error(`Account creation failed: ${err}`);
    });

    if (response.data.error) {
      throw Error(`Account creation failed: ${response.data.error.message}`);
    } else if (response.data.userInfo.userConfirmed) {
      setSubmitSuccess(true);
    }

    return {};
  };

  // post to parent that content has mounted
  useEffect(() => {
    if (containerRef.current) {
      Window.parent.postMessage(
        {
          type: 'createAccountMount',
          height: containerRef.current.offsetHeight,
        },
        parentOrigin
      );
    }
  }, [containerRef]);

  // handle content resize
  useLayoutEffect(() => {
    const debouncedHandleResize = debounce(() => {
      Window.parent.postMessage(
        {
          type: 'createAccountResize',
          height: containerRef.current.offsetHeight,
        },
        parentOrigin
      );
    }, 300);

    if (containerRef.current) {
      debouncedHandleResize();
    }

    Window.addEventListener('resize', debouncedHandleResize);
    return () => Window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  // post to parent that cognito user has been created
  useEffect(() => {
    if (submitSuccess) {
      Window.parent.postMessage({ type: 'createAccountSuccess' }, parentOrigin);
    }
  }, [submitSuccess]);

  return (
    <div ref={containerRef} className={classes.container}>
      <LoginForm
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        cta={createScreenText.createPassword}
        screen="create-embed"
        initialValues={initialValues}
        fields={[
          {
            type: 'email',
            label: 'Email',
            name: 'email',
            disabled: disableEmail,
          },
          {
            type: 'password',
            label: 'Password',
            name: 'password',
          },
        ]}
      />
    </div>
  );
};
