import axios from 'axios';
import { useState } from 'react';
import { EARNUP_UID_COOKIE } from '../config';
import Cookies from 'universal-cookie';

type Person = {
  personId: string;
  email: string;
};

const getCreateAccount =
  (personId: string | null, email: string | null | undefined) =>
  async (password = '') => {
    if (!personId) {
      return () => {
        // TODO: Maybe escalate some err state?
      };
    }
    const response = await axios({
      method: 'post',
      url: '/create-account',
      data: {
        username: email,
        password: password,
        customer_id: '',
        uis_id: personId,
      },
    }).catch((err) => {
      if (403 === err.response.status) {
        throw Error(JSON.stringify(err.response.data));
      }

      throw Error(
        `Failed to create account: ${err.response.status} ${err.response.statusText}`
      );
    });
    const respData = response && response.data ? response.data : {};
    return respData;
  };

class RequestManager {
  requestInFlight = false;
  setCookiesResponse: any;
  headers: any;
  static instance: RequestManager;

  constructor() {
    if (RequestManager.instance) {
      return RequestManager.instance;
    }
    RequestManager.instance = this;
  }

  async setCookiesFromRequestToken(
    requestToken: string | null | undefined | void,
    callback: () => void
  ): Promise<boolean> {
    if (this.requestInFlight || this.setCookiesResponse) {
      // Don't allow duplicate requests
      callback();
      return false;
    }
    this.requestInFlight = true;

    // Set our cookies from the user request token
    const setCookiesResponse = await axios({
      method: 'post',
      url: '/login_request_token',
      data: {
        requestToken: requestToken,
      },
    }).catch((err) => {
      this.requestInFlight = false;
      console.log({ err });
      return false;
    });
    this.requestInFlight = false;
    this.setCookiesResponse = setCookiesResponse;
    callback();
    return true;
  }
}

export const usePerson = (
  fromToken: string | null | undefined | void
): {
  id: string | null;
  loading: boolean;
  createAccount: (password: string) => any;
  email: string;
} => {
  const [cookiesAreSet, setCookiesAreSet] = useState(false);
  const requestManager = new RequestManager();
  const identifyBorrower = () => {
    setCookiesAreSet(true);
  };
  if (!requestManager.requestInFlight && !cookiesAreSet) {
    if (fromToken) {
      requestManager.setCookiesFromRequestToken(fromToken, identifyBorrower);
    } else {
      identifyBorrower();
    }
  }
  const cookies = new Cookies();
  const email = requestManager.setCookiesResponse?.data?.email;
  const uidCookie = cookies.get(EARNUP_UID_COOKIE);
  const personId = uidCookie?.uis_id ?? uidCookie?.['custom:uis_id'] ?? null;
  const createAccount = getCreateAccount(personId, email);
  return {
    id: personId,
    loading: requestManager.requestInFlight,
    createAccount,
    email,
  };
};
