import { useQueryParam, StringParam } from 'use-query-params';
import Cookies from 'universal-cookie';
import {
  EARNUP_JS_COOKIE,
  EARNUP_UID_COOKIE,
  EARNUP_COOKIE_DOMAIN,
  DEFAULT_REDIRECT_URL,
} from '../config';

interface Token {
  idToken: {
    jwtToken: string;
    payload: string;
  };
  refreshToken: {
    token: string;
  };
}

export const useRedirect = (forcedRedirectUrl?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let redirectUrl: any;
  [redirectUrl] = useQueryParam('redirect_url', StringParam);
  if (forcedRedirectUrl) {
    redirectUrl = forcedRedirectUrl;
  }
  const uriSafeRedirectURL = decodeURIComponent(redirectUrl as string);
  const redirect = (token: Token) => {
    if (
      /localhost$/.test(window.location.hostname as string) ||
      (/(http|https):\/\/localhost:\d{4}(\/.*|$)/.test(uriSafeRedirectURL) &&
        /dev\..*earnup.com$/.test(window.location.hostname as string))
    ) {
      if (redirectUrl) {
        const jwtToken = token.idToken;
        const refreshToken = token.refreshToken;
        const cookies = new Cookies();
        const uidToken = cookies.get(EARNUP_UID_COOKIE);
        const jsToken = cookies.get(EARNUP_JS_COOKIE);

        window.location.assign(
          `${uriSafeRedirectURL}?token=${jwtToken}&refresh_token=${refreshToken}&js_token=${encodeURIComponent(
            JSON.stringify(jsToken)
          )}&uid_token=${encodeURIComponent(JSON.stringify(uidToken))}`
        );
      } else {
        window.location.assign(DEFAULT_REDIRECT_URL);
      }
    } else if (redirectUrl) {
      const redirectURLAsObject = new URL(uriSafeRedirectURL);
      const { hostname, protocol } = redirectURLAsObject;
      if (!['http', 'https'].includes(protocol.replaceAll(':', ''))) {
        // Valid URL but invalid protocol, likely attempted XSS
        // Throw an error so if rollbar is enabled it will report

        throw new Error(
          'Invalid protocol! Attempted XSS suspected. Logging IP...'
        );
      }
      const regex = new RegExp(`${EARNUP_COOKIE_DOMAIN}$`);
      if (!regex.test(hostname)) {
        return;
      }
      window.location.assign(uriSafeRedirectURL);
    } else {
      window.location.assign(DEFAULT_REDIRECT_URL);
    }
  };

  return [redirect];
};
