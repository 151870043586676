import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import {
  Email,
  Password,
  Grid,
  SubmitButton as Button,
  Form,
  Typography,
  makeStyles,
  LoadingGate,
} from '@earnup/olympic-lib';

import { LoginForm } from '../components';
import { ENTERPRISE_AGENT_ENROLLMENT_FLOW } from '../constants/i18n';
import { isDevClientSide } from '../utils/envutils';
import { usePerson } from '../hooks/usePerson';

import createValidationSchema from '../validation/create';

const isAllowedOrigin = (origin = ''): boolean => {
  const devOverride = origin.match(/localhost:\d+$/) && isDevClientSide();
  return Boolean(origin.match(/earnup.com$/) || devOverride);
};

const styles = (theme: any) => ({
  container: {
    padding: theme.spacing(4),
  },
  heading: {
    paddingTop: theme.spacing(1),
  },
  instructions: {
    fontSize: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
  },
});
const enterpriseOutlineOverrideProps = {};

const useStyles = makeStyles(styles);
export const EnterpriseAgentAccountCreation = () => {
  const classes = useStyles();
  const [parentOrigin]: any = useQueryParam('origin', StringParam);
  const [requestToken] = useQueryParam('rt', StringParam);
  const origin = isAllowedOrigin(parentOrigin)
    ? parentOrigin
    : 'https://app.earnup.com';
  const { loading, email } = usePerson(requestToken);

  const validationSchema = createValidationSchema();
  const initialValues = {
    password: '',
    email,
  };
  const onSubmit = () => {
    console.log('submit');
  };
  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {(formProps: any) => {
        const { status, isSubmitting } = formProps;
        const message = ''; //getFormMessage(status);
        const success = true; // isSuccess(status);
        return (
          <>
            <LoadingGate condition={!loading}>
              <Grid
                container
                item
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                xs={12}
                md={7}
                className={classes.container}
              >
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="left"
                  className={classes.heading}
                >
                  <Typography variant="h6" Component="h1">
                    {ENTERPRISE_AGENT_ENROLLMENT_FLOW.HEADER}
                  </Typography>
                </Grid>
                <Grid
                  item
                  margin={2}
                  xs={12}
                  align="left"
                  className={classes.instructions}
                >
                  <Typography>
                    {/* TODO: Pull in bank name https://earnup.atlassian.net/browse/APPS-1816 */}
                    {ENTERPRISE_AGENT_ENROLLMENT_FLOW.HEADER}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Password
                    dataQa="password"
                    label={ENTERPRISE_AGENT_ENROLLMENT_FLOW.PASSWORD}
                    helperText={
                      ENTERPRISE_AGENT_ENROLLMENT_FLOW.PASSWORD_REQUIREMENTS
                    }
                    className={classes.enterpriseInputField}
                    {...enterpriseOutlineOverrideProps}
                  />
                </Grid>
              </Grid>
            </LoadingGate>
          </>
        );
      }}
    </Form>
  );
};
