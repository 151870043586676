import React, { FC } from 'react';
import { Theme } from '@material-ui/core/styles';
import { Typography, Spacing, makeStyles } from '@earnup/olympic-lib';

const styles = (theme: Theme) => ({
  paper: {
    margin: -theme.spacing(5),
    marginBottom: theme.spacing(3),
    borderRadius: '10px 10px 0 0',
    background: (props: any) =>
      props.contrastTop ? theme.palette.primary.main : '#FFF',
    padding: theme.spacing(5),
    paddingBottom: 0,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
  },
  copy: (props: any) => ({
    ...{ color: props.contrastTop ? '#FFF' : undefined },
  }),
});
const useStyles = makeStyles(styles);

export const FormHeader: FC<{
  contrastTop?: boolean;
  heading: string;
  subHeading?: string;
}> = ({ contrastTop, heading, subHeading }) => {
  const classes = useStyles({ contrastTop });
  return (
    <div className={classes.paper}>
      <Spacing marginBottom={3}>
        <Typography className={classes.copy} variant="h5" component="h1">
          {heading}
        </Typography>
      </Spacing>
      <Spacing marginBottom={2}>
        <Typography className={classes.copy} variant="body1" component="p">
          {subHeading}
        </Typography>
      </Spacing>
    </div>
  );
};
