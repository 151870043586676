export const loginText = {
  createOneNow: `Create one now`,
  dontHaveAccountYet: "Don't have an account yet?",
  forgotPassword: 'Forgot password',
  emailLabel: 'Email',
  passwordLabel: 'Password',
  loginToYourAccount: 'Log in to your account',
  welcome: 'Welcome! Please log in to your account to continue.',
  loginFailed: 'Login failed',
  welcomeBack: 'Welcome back!',
  alreadyHaveAccountText: (isPartner) =>
    `It looks like you already have an account set up with ${
      isPartner ? 'us' : 'Earnup'
    }. Login below to access it.`,
};

export const createScreenText = {
  welcome: 'Welcome!',
  almostReady:
    'You’re almost ready to manage your payments online with EarnUp. Please set up a password to access your information.',
  createPassword: 'Create Password',
  createAccount: 'Create Account',
};

export const ENTERPRISE_BORROWER_ENROLLMENT_FLOW = {
  HEADER: 'Create an EarnUp Account',
  // TODO: Pull in Bank Name https://earnup.atlassian.net/browse/APPS-1816
  INSTRUCTIONS: (bankName) =>
    `Thanks! EarnUp has matched you with your mortgage account. Now, finish creating your EarnUp account to set up a flexible payment schedule for your ${
      bankName ? bankName + ' ' : ''
    }mortgage.`,
};

export const ENTERPRISE_AGENT_ENROLLMENT_FLOW = {
  HEADER: 'TODO: i18N',
  PASSWORD: 'Password',
  PASSWORD_REQUIREMENTS: '10+ characters, at least 1 uppercase and a number',
};
