import React from 'react';
import {
  createTheme,
  ThemeProvider,
  HelmetProvider,
  makeStyles,
  Rollbar,
  Segment,
  LoadingGate,
} from '@earnup/olympic-lib';
import {
  Login,
  Change,
  Forgot,
  Create,
  CreateEmbed,
  SemiAuthRequestTokenEmbed,
  SemiAuthEmbed,
  SemiAuthCreateAccountEmbed,
  BorrowerEnrollmentAccountCreation,
  EnterpriseAgentAccountCreation,
} from './screens';
import { Home } from './screens/home';
import { CookiesProvider } from 'react-cookie';
import { QueryParamProvider } from 'use-query-params';
import { Header } from './components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  ROLLBAR_ACCESS_TOKEN,
  EARNUP_ENV,
  PUBLIC_URL,
  SEGMENT_KEY,
} from './config';
import { GET_PARTNER_CONFIG } from './gql/partnerConfig';
import { useQuery } from '@apollo/client';
import { getSubdomain } from './utils/theme';

export const styles = (theme: any) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  wrapper: {
    height: '100%',
    width: '100%',
  },
});

const useStyles = makeStyles(styles);

// We need a RouteWithHeader component, so that we can optionally omit the header for some routes
// without doing any gross CSS hacks
const RouteWithHeader = ({
  children,
  logoPath,
  path,
}: {
  children: any;
  logoPath: string;
  path: string;
}) => {
  return (
    <>
      <Header logo={logoPath} />
      <Route path={path}>{children}</Route>
    </>
  );
};

function App() {
  const classes = useStyles();

  const subdomain = getSubdomain(window.location.hostname);

  const { loading, error, data } = useQuery(GET_PARTNER_CONFIG, {
    variables: { subdomain },
  });

  const theme = data?.get_partner_configuration?.theme;

  const logoPath = data?.get_partner_configuration?.logo;

  return (
    <div className={classes.wrapper}>
      <ThemeProvider theme={createTheme(theme)}>
        <HelmetProvider>
          <Rollbar token={ROLLBAR_ACCESS_TOKEN} env={EARNUP_ENV} />
          <Segment apiKey={SEGMENT_KEY} />
          <CookiesProvider>
            <Router basename={PUBLIC_URL}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <LoadingGate condition={!loading}>
                  <Switch>
                    <RouteWithHeader logoPath={logoPath} path="/forgot">
                      <Forgot />
                    </RouteWithHeader>
                    <RouteWithHeader logoPath={logoPath} path="/login">
                      <Login />
                    </RouteWithHeader>
                    <RouteWithHeader logoPath={logoPath} path="/change">
                      <Change />
                    </RouteWithHeader>
                    <RouteWithHeader logoPath={logoPath} path="/create">
                      <Create />
                    </RouteWithHeader>
                    <RouteWithHeader logoPath={logoPath} path="/create-embed">
                      <CreateEmbed />
                    </RouteWithHeader>
                    <RouteWithHeader
                      logoPath={logoPath}
                      path="/semi-auth-request-token-embed"
                    >
                      <SemiAuthRequestTokenEmbed />
                    </RouteWithHeader>
                    <RouteWithHeader
                      logoPath={logoPath}
                      path="/semi-auth-embed"
                    >
                      <SemiAuthEmbed />
                    </RouteWithHeader>
                    <RouteWithHeader
                      logoPath={logoPath}
                      path="/semi-auth-create-account-embed"
                    >
                      <SemiAuthCreateAccountEmbed />
                    </RouteWithHeader>
                    {/*
                     * the enrollment routes do not have the header included
                     * and uses the plain <Route> component
                     */}
                    <Route path="/borrower-enrollment">
                      <BorrowerEnrollmentAccountCreation />
                    </Route>
                    <Route path="/agent-enrollment">
                      <EnterpriseAgentAccountCreation />
                    </Route>
                    <RouteWithHeader logoPath={logoPath} path="/">
                      <Home />
                    </RouteWithHeader>
                  </Switch>
                </LoadingGate>
              </QueryParamProvider>
            </Router>
          </CookiesProvider>
        </HelmetProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
