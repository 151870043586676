import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { useQueryParam, StringParam } from 'use-query-params';
import { makeStyles } from '@earnup/olympic-lib';

import { LoginForm } from '../components';
import { createScreenText } from '../constants/i18n';
import createValidationSchema from '../validation/create';

const styles = (theme: any) => ({
  container: {
    paddingTop: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);

export const waitAndSendResizeEvent = (
  containerRef: any,
  parentOrigin: any
) => {
  setTimeout(() => {
    window.parent.postMessage(
      {
        type: 'semiAuthCreateAccountEmbedResize',
        height: containerRef.current.offsetHeight,
      },
      parentOrigin
    );
  }, 1);
};

export const SemiAuthCreateAccountEmbed = () => {
  const classes = useStyles();
  const [parentOrigin]: any = useQueryParam('origin', StringParam);
  const containerRef = React.useRef() as React.MutableRefObject<any>;
  const validationSchema = createValidationSchema();
  const initialValues = {
    email: '',
    password: '',
  };
  const [user, setUser]: any = useState({
    uisId: '',
    confirmed: false,
    email: initialValues.email,
  });
  let response;

  const handleSubmit = async (event: any) => {
    response = await axios({
      method: 'post',
      url: '/create-account',
      data: {
        username: event.email,
        password: event.password,
        customer_id: '',
        uis_id: user.uisId,
      },
    }).catch((err) => {
      // Need to wait a cycle to ensure the error renders before we postMessage to parent.
      // Not the best, but the form that renders the error shouldn't have to worry about this.
      waitAndSendResizeEvent(containerRef, parentOrigin);

      if (403 === err.response.status) {
        throw Error(JSON.stringify(err.response.data));
      }
      throw Error(
        `Failed to create account: ${err.response.status} ${err.response.statusText}`
      );
    });

    const respData = response && response.data ? response.data : {};
    const userInfo = respData.userInfo ? respData.userInfo : {};
    if (userInfo.userConfirmed) {
      setUser({
        ...user,
        confirmed: true,
        email: event.email,
      });
    }

    return {};
  };

  const getPersonId = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: '/identify',
        data: {},
      });
      return response.data.jwtTokenPayload.personId;
    } catch (e) {
      waitAndSendResizeEvent(containerRef, parentOrigin);
      throw Error('Semi Auth /identify endpoint failed');
    }
  };

  // On load, get personId and set user
  useEffect(() => {
    const handler = async (event: any) => {
      if (event.data.type === 'getAuth') {
        const personId = await getPersonId();
        setUser({
          ...user,
          uisId: personId,
        });
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  // post to parent that cognito user has been created
  useEffect(() => {
    if (user.confirmed) {
      window.parent.postMessage(
        {
          type: 'semiAuthCreateAccountEmbedSuccess',
          email: user.email,
          uisId: user.uisId,
        },
        parentOrigin
      );
    }
  }, [user.confirmed]);

  // handle content mount
  useEffect(() => {
    if (containerRef.current) {
      window.parent.postMessage(
        {
          type: 'semiAuthCreateAccountEmbedMount',
          height: containerRef.current.offsetHeight,
        },
        parentOrigin
      );
    }
  }, [containerRef]);

  // handle content resize
  useLayoutEffect(() => {
    const debouncedHandleResize = debounce(() => {
      window.parent.postMessage(
        {
          type: 'semiAuthCreateAccountEmbedResize',
          height: containerRef.current.offsetHeight,
        },
        parentOrigin
      );
    }, 300);

    if (containerRef.current) {
      debouncedHandleResize();
    }
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return (
    <div ref={containerRef} className={classes.container}>
      <LoginForm
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        cta={createScreenText.createAccount}
        screen="semi-auth-create-account-embed"
        initialValues={initialValues}
        fields={[
          {
            type: 'email',
            label: 'Email',
            name: 'email',
          },
          {
            type: 'password',
            label: 'Password',
            name: 'password',
          },
        ]}
      />
    </div>
  );
};
